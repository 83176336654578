// ----------------
// Swiper Modules
// ----------------
import {SyncedTestimonialSlider} from '../../carousels/SyncedTestimonialSlider';
import {TestimonialSliderStaticBg} from '../../carousels/TestimonialSliderStaticBg';
import {RelatedPostsSlider} from '../../carousels/RelatedPostsSlider';
import {ProductPagePhotoSlider} from '../../carousels/ProductPagePhotoSlider';
import {ProductPageConfigurations} from '../../carousels/ProductPageConfigurations';

function init() {
    let $sliders = document.querySelectorAll('[data-venveo-slider]')
    let sliders = [];
    $sliders.forEach(($element) => {
        // This will tell us the type of slider to create
        let type = $element.dataset.venveoSlider;
        let slider = null;
        switch(type) {
            case 'SyncedTestimonialSlider':
                slider = new SyncedTestimonialSlider($element)
                break
            case 'TestimonialSliderStaticBg':
                slider = new TestimonialSliderStaticBg($element)
                break
            case 'RelatedPostSlider':
                slider = new RelatedPostsSlider($element)
                break
            case 'ProductPagePhotoSlider':
                slider = new ProductPagePhotoSlider($element)
                break
            case 'ProductPageConfigurations':
                slider = new ProductPageConfigurations($element)
                break
            default:
                console.error('No such slider ' + type + ' on element', $element)
        }

        if (slider) {
            sliders.push(slider);
        }
    })
}

export default init
