import {A11y, Autoplay, Controller, EffectFade, History, Keyboard, Lazy, Navigation, Swiper, Thumbs} from 'swiper/dist/js/swiper.esm'
import {AbstractCarousel} from './AbstractCarousel';
// Use modules
Swiper.use([Keyboard, Navigation, Controller, History, Autoplay, Lazy, EffectFade, A11y, Thumbs])

const breakpoint = window.matchMedia('(max-width: 851px)');
/**
 * This is a variant of the testimonial slider where there are multiple photos
 * as well as multiple testimonial boxes.
 *
 * The constructor takes the id of the main slider wrapper, usually c-related-post__inner
 */
export class RelatedPostsSlider extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode)

        this.settings = {
            relatedPostSwiper: {
                navigation: {
                    nextEl: '.c-related-post__slidernav-next',
                    prevEl: '.c-related-post__slidernav-prev',
                },
                autoplay: {
                    delay: 5000,
                },
                speed: 500,
                breakpoints: {
                    // when window width is <= 640px
                    850: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    },
                    // when window width is <= 640px
                    639: {
                        autoHeight: true
                    }
                }
            }
        }

        Object.assign(this.settings, options)
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.

        this.$sliderContainer = this.$root.querySelector('[data-swiper-relatedpost]')

        breakpoint.addListener(this.breakpointChecker.bind(this))
        this.breakpointChecker()
    }


    breakpointChecker() {
        if ( breakpoint.matches || breakpoint.matches === true ) {
            if (!this.relatedPostSwiper) {
                this.relatedPostSwiper = new Swiper(this.$sliderContainer, this.settings.relatedPostSwiper);
            }
        } else if ( breakpoint.matches === false ) {
            if (this.relatedPostSwiper) {
                this.relatedPostSwiper.destroy()
                this.relatedPostSwiper = null;
            }
        }
    }
}


