// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------------------------------
import logo from './logo'
import searchNavbar from './navbar-search'
import swiperModules from './swiper'
import foxblocksDiagram from './foxblocksDiagram'
import forms from './forms'
import video from './video'
import downloadAuth from '../../components/downloadAuth'

function init() {
    logo()
    searchNavbar()
    forms()
    swiperModules()
    foxblocksDiagram()
    // video()
    downloadAuth()
}

export default init
