import $ from "jquery";
import {setQueryStringParameter} from "../../utils/historyUtils";

export default function init() {
    if (!document.getElementById('resources-listing-v2')) {
        return;
    }
    $('[data-accordion]').on('down.zf.accordion', function(e, $element) {
        const categorySlug = $element.data('category')
        if (categorySlug) {
            setQueryStringParameter('category', categorySlug)
        }
    });

    function updateClearButtonVisibility() {
        const $searchInput = $('#resources-search-bar')
        if ($searchInput.val() && $searchInput.val().length) {
            document.getElementById('clear-search').classList.add('visible')
        } else {
            document.getElementById('clear-search').classList.remove('visible')
        }
    }

    updateClearButtonVisibility()
    $('body').on('keyup','#resources-search-bar', function() {
        console.log('Keyup')
        updateClearButtonVisibility()
    });

    $('body').on('click', '#clear-search', function(e, $element) {
        const $searchInput = $('#resources-search-bar')
        $searchInput.val('')
        updateClearButtonVisibility()
        // if (initialSearchValue) {
            htmx.trigger('#resourcesResults', 'refresh');
        // }
    })
}
