/**
 * MODULE EVENTS
 * This module handles all the events for the modal/opening downloads
 */
import config from './config'

/**
* This is the main error handler that displays errors.
* @param {object} response
*/
export function errorHandler(response) {
    throw(response)
}

/**
 * This object handles loading values for the module.
 * @param None
 */
export const loading = {
    start: () => {
        config.loadingIcon.show()
    },
    stop: () => {
        config.loadingIcon.hide()
    },
    setLoaderState: (loadingText = '', loaderState = 'show') => {
        config.loadingIcon.find('h4').text(loadingText)
        config.loadingIcon.find('img')[loaderState]()
    }
}

/**
 * This is the function that should be called when a user is
 * successfully authenticated. It opens their selected asset.
 * @param {object} assetData
 */
export function openAsset(assetData = {}) {
    if (assetData.downloadURL) {
        window.location.href = assetData.downloadURL
    } else {
        alert('Sorry, that link does not seem to exist, try again later or contact us.')
    }
}

/**
 * This is the function that should be called when a user is
 * NOT successfully authenticated. It opens the signup form.
 * @param {object} assetData
 */
export function openSignup(assetData) {
    config.modal.foundation('open')
    config.modal.find('form').attr('data-auth-url', assetData.downloadURL)
}

export function closeSignup() {
    config.modal.foundation('close')
}
