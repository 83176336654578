/**
 * Find A Dealer
 *
 * This module contains the code for the Find A Dealer page.
 *
 */

/**
 * Main handler for the location search validator.
 */
function validateLocationSearch() {
    const locatorForm = document.querySelector('#dealer-locator')
    if (locatorForm) {
        const locationInput = locatorForm.querySelector('#near')
        locatorForm.addEventListener('submit', function(e) {
            const value = locationInput.value
            if (checkValue(value)) {
                hideErrorMessage(locatorForm)
                return true
            } else {
                e.preventDefault()
                showErrorMessage(locatorForm)
                return false
            }
        })
    }
}

/**
 * Checks to see if the string is long enough.
 * @param {String} value Value to check.
 */
function checkValue(value) {
    return value.length > 2
}

/**
 * Show the error-text within this form.
 * @param {Element} locatorForm Form to look inside
 */
function showErrorMessage(locatorForm) {
    const errorText = locatorForm.querySelector('.error-text')
    errorText.classList.add('show')
}

/**
 * Hide the error-text within this form.
 * @param {Element} locatorForm Form to look inside
 */
function hideErrorMessage(locatorForm) {
    const errorText = locatorForm.querySelector('.error-text')
    errorText.classList.remove('show')
}

/**
 * Find a dealer tab listeners.
 */
function findADealerTabs() {
    if (document.getElementById('finddealer-tabs')) {
        $('#finddealer-tabs').on('change.zf.tabs', function(event, tab) {
            if (smartMap.listMarkers().length) {
                smartMap.fitBounds('smartmap-mapcanvas-1')
            }
        })
    }
}

/**
 * Init function
 */
export default function init() {
    findADealerTabs()
    validateLocationSearch()
}
