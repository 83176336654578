import  '../fonts/biosans-regular-webfont.eot'
import  '../fonts/biosans-regular-webfont.woff'
import  '../fonts/biosans-regular-webfont.woff2'

import  '../fonts/biosans-bold-webfont.eot'
import  '../fonts/biosans-bold-webfont.woff'
import  '../fonts/biosans-bold-webfont.woff2'

import  '../fonts/biosans-semibold-webfont.eot'
import  '../fonts/biosans-semibold-webfont.woff'
import  '../fonts/biosans-semibold-webfont.woff2'

import  '../fonts/proximanova-regular-webfont.eot'
import  '../fonts/proximanova-regular-webfont.woff'
import  '../fonts/proximanova-regular-webfont.woff2'

import  '../fonts/proximanova-semibold-webfont.eot'
import  '../fonts/proximanova-semibold-webfont.woff'
import  '../fonts/proximanova-semibold-webfont.woff2'