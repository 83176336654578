// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------------------------------
import dealerLocator from './dealerLocator'
import resourcesV2 from './resourcesV2'

function init()
{
    dealerLocator()
    resourcesV2()
}

export default init
