/**
 * Custom Dropdown Component
 *
 *
 */
import dropJS from './dropdown'

function check() {
    const customDropdown = document.querySelectorAll('[data-dropjs]')
    return !!(customDropdown.length > 0)
}

function init() {
    if (!check()) return
    dropJS()
}

export default init
