import $ from 'jquery'
import 'what-input'
import * as components from './components'
import 'intersection-observer'
import 'custom-event-polyfill'
import 'url-polyfill'

import './static-assets'
import './lib/foundation.plugins'

import '../sass/app.scss'

window.$ = $
window.jQuery = $

const setupPages = (function() {
    components.globalJS()
    components.pagesJS()
    // modal({images: window.modalImgs})
    // slider({images: window.sliderImgs})
    // finishSlider({finish: window.finish})
    // productFinishSlider({finishes: window.finishes, priceGroups: window.priceGroups})

})()

const mainInit = (function() {
    setupPages
})()

function Main() {
    this.init = function() {
        $(document).ready(mainInit)
        $(document).foundation()
    }
    return {
        init: this.init
    }
}

const main = new Main()
main.init()

document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

const lazyLoad = async() => {
    const LazySizes = await import(/* webpackChunkName: "lazysizes" */ 'lazysizes');
    LazySizes.cfg.lazyClass = 'lazy';
    LazySizes.init();
};
// Execute async function
lazyLoad().then(() => {
});


if (module.hot) {
    module.hot.accept()
}
