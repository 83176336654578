import { Swiper, Keyboard, Navigation, Controller, History, Autoplay, Lazy, EffectFade, A11y, Thumbs } from 'swiper/dist/js/swiper.esm'
// Use modules
Swiper.use([ Keyboard, Navigation, Controller, History, Autoplay,  Lazy, EffectFade, A11y, Thumbs ])

import {AbstractCarousel} from './AbstractCarousel';

/**
 * This is a variant of the testimonial slider where there are multiple photos
 * as well as multiple testimonial boxes.
 *
 * The constructor takes the id of the main slider wrapper, usually c-slider__inner
 */
export class SyncedTestimonialSlider extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        let settings = {
            testimonialBlock: {
                slidesPerView: 1,
                autoHeight: true,
                freeMode: false,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                speed: 700,
            },
            testimonialPhoto: {
                lazy: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                keyboard: {
                    enabled: true,
                },
                speed: 700,
                navigation: {
                    nextEl: '.c-slider__slidernav-next',
                    prevEl: '.c-slider__slidernav-prev',
                }
            }
        }
        Object.assign(settings, options)
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.
        super(rootNode);

        this.$thumbSliderContainer = this.$root.querySelector('[data-sliderthumb-block-swiper]');
        this.$sliderContainer = this.$root.querySelector('[data-sliderthumb-swiper]');

        this.testimonialBlock = new Swiper(this.$thumbSliderContainer, settings.testimonialBlock)

        this.testimonialPhoto = new Swiper(this.$sliderContainer, settings.testimonialPhoto);

        this.testimonialPhoto.controller.control = this.testimonialBlock;
        this.testimonialBlock.controller.control = this.testimonialPhoto;
    }
}


