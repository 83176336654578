import apisauce from 'apisauce'
import config from './config'
import qs from 'qs'

const api = apisauce.create({
    baseURL: config.baseURL,
    timeout: 30000,
    headers: {
        'content-type': 'application/x-www-form-urlencoded'
    }
})

async function getAuthentication(assetId) {
    return await api.get(`download/getURL/${assetId}`)
}

export default {
    getAuthentication
}
