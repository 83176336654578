/**
 * Authentication/ Functions
 */
import api from './api'
import { openAsset, openSignup, closeSignup, loading, errorHandler } from './events'
import $ from "jquery";

/**
 * This is the primary function that authenticates the user and returns the asset data.
 * @param {string} assetId
 * @returns {object} Will be an object containing the authenticated value, and asset data.
 */
async function authenticate(assetId) {
    // Make API call
    const response = await api.getAuthentication(assetId)
    if (!response.data.error) {
        return response.data
    } else {
        errorHandler(response)
    }
}

/**
 * This is the start of the process, the click handler.
 * @param {string} assetId
 */
async function handleDownloadClick(assetId, $link) {
    // Start loading
    loading.start()
    $link.addClass('loading')

    // Go get the authentication data
    const assetData = await authenticate(assetId)
    $link.removeClass('loading')

    // If authenticated...
    if (assetData.authenticated) {
        openAsset(assetData)
    } else {
        // If not authenticated...
        openSignup(assetData)
        const form = $('.download-signup-form')[0];
        form.freeform.addOnSuccessfulAjaxSubmit((event, form, response) => {
            closeSignup()
            openAsset(assetData)
        })
    }
}

export {
    handleDownloadClick
}
