import {A11y, Autoplay, Controller, EffectFade, History, Keyboard, Lazy, Navigation, Swiper, Thumbs} from 'swiper/dist/js/swiper.esm'
import {AbstractCarousel} from './AbstractCarousel';
// Use modules
Swiper.use([Keyboard, Navigation, Controller, History, Autoplay, Lazy, EffectFade, A11y, Thumbs])

/**
 * This is a variant of the testimonial slider where there are multiple photos
 * as well as multiple testimonial boxes.
 *
 * The constructor takes the id of the main slider wrapper, usually c-related-post__inner
 */
export class ProductPagePhotoSlider extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode)

        this.settings = {
            galleryThumbs: {
                slidesPerView: 4,
                spaceBetween: 13,
                freeMode: true,
                preventClicks: false,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            },
            galleryPreview: {
                keyboard: {
                    enabled: true,
                },
                watchOverflow: true,
                lazy: true,
                navigation: {
                    nextEl: '.prod-gallery__slidernav-next',
                    prevEl: '.prod-gallery__slidernav-prev',
                },
            }
        }

        Object.assign(this.settings, options)
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.

        this.$sliderContainer = this.$root.querySelector('[data-prodgallery-preview]')
        this.$sliderThumbsContainer = this.$root.querySelector('[data-prodgallery-thumbs]')

        this.galleryThumbs = new Swiper(this.$sliderThumbsContainer, this.settings.galleryThumbs);
        this.settings.galleryPreview.thumbs = {swiper: this.galleryThumbs};
        this.galleryPreview = new Swiper(this.$sliderContainer, this.settings.galleryPreview);
    }
}


