/**
 * Configuration object for module
 */
import $ from 'jquery'

export default {
    apiUrl: (endpoint) => `${window.location.origin}/api/${endpoint}`,
    baseURL: `${window.location.origin}/api/`,
    modal: $('#resource-download-modal'),
    loadingIcon: $('.modal-downloads .loading-icon')
}
