/**
 * Download Authentication Component
 * This component tells the tale of front end users downloading
 * assets/images. The story is filled with API's, cookies, and danger.
 */
import controller from './controller'

function check() {
    // const dataLinks = document.querySelectorAll('[data-download]')
    // return !!(dataLinks.length > 0)
    return true;
}

function init() {
    if (!check()) return
    controller()
}

export default init
