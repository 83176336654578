let videos = Array.from(document.querySelectorAll('[data-video]'))
let yplayer, ytvideo
var yplayers = new Array()

function initYTplayer(video) {
    ytvideo = video
    let id = video.getAttribute('data-video')
    yplayer = new YT.Player(id, {
        playerVars: {
            'rel': 0,
            'showinfo': 0,
            'playsinline': 1,
            'autoplay': 1
        },
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    })
}

function onPlayerReady(event) {
    // play video
    event.target.playVideo();
    // yplayer.playVideo()
    setVideoArr(event.target)
}

function setVideoArr(video) {
    unsetVideoArr()
    yplayers.push(video)
}

function unsetVideoArr() {
    yplayers = []
}

export function pausePreviousVideo() {
    if (yplayers.length > 0) {
        yplayers.forEach(function (videoPlaying) {
            setTimeout(function () {
                videoPlaying.pauseVideo()
            }, 500)
        })
    }
}

function onPlayerStateChange(event) {
    if (event.data === YT.PlayerState.BUFFERING) {
        let elThumb = ytvideo.querySelector('[data-video-thumb]')
        elThumb.classList.remove('video-player--buffering')
        elThumb.classList.remove('video-player--paused')
        elThumb.classList.add('video-player--playing')
    }

    if (event.data === YT.PlayerState.PLAYING) {
        let elThumb = ytvideo.querySelector('[data-video-thumb]')
        elThumb.classList.remove('video-player--buffering')
        elThumb.classList.remove('video-player--paused')
        elThumb.classList.add('video-player--playing')
        setVideoArr(event.target)
    }

    if (event.data === YT.PlayerState.PAUSED) {
        unsetVideoArr()
    }

    if (event.data === YT.PlayerState.ENDED) {
        ytvideo.querySelector('[data-video-thumb]').classList.remove('video-player--playing')
        unsetVideoArr()
    }
}

function init() {
    if (videos.length !== 0) {

        var tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        var firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)


        videos.forEach(function (video) {
            // Click event
            video.addEventListener('click', function () {
                video.querySelector('[data-video-thumb]').classList.add('video-player--buffering')
                initYTplayer(video)
            })
        })

    }
}

export default init
export {yplayers}
