let navbarSearchInput = document.querySelector('[data-navsearch-input]')
let navbarSearchButton = Array.from(document.querySelectorAll('[data-navbar-search-trigger]'))
let navbar = document.querySelector('[data-site-navbar]')

function onClick() {
    if( !navbar.classList.contains('search-is-active') ){
        return expandSearch()
    } else if ( navbar.classList.contains('search-is-active') ){
        return hideSearch()
    }
}

function expandSearch() {
    navbar.classList.add('search-is-active')
    setTimeout(setInputFocus, 400)
}

function setInputFocus() {
    navbarSearchInput.focus()
}

function hideSearch() {
    navbar.classList.remove('search-is-active')
    navbarSearchInput.blur()
}

function init() {
    if(navbarSearchButton.length != 0) {
        navbarSearchButton.forEach(function(trigger){
            trigger.addEventListener('click', onClick, false)
        })
    }
}

export default init
