// Diagram Badge Hover Effect
// ---

function init() {
    if( document.querySelector('.foxblocks-wall-diagram') ) {
        let numberList = document.querySelectorAll('[data-foxblocks-diagram-no]')
        let classNameNun = 'foxblocks-diagram-no-'

        Array.from(numberList).forEach(element => {
            element.addEventListener('mouseover', function() {
                let elementID = classNameNun+this.getAttribute('data-foxblocks-diagram-no')
                document.getElementById(elementID).classList.add('is-hovered')
            });

            element.addEventListener('mouseout', function() {
                let elementID = classNameNun+this.getAttribute('data-foxblocks-diagram-no')
                document.getElementById(elementID).classList.remove('is-hovered')
            });
        })
    }
}

export default init
