import { Swiper, Keyboard, Navigation, Controller, History, Autoplay, Lazy, EffectFade, A11y, Thumbs } from 'swiper/dist/js/swiper.esm'
// Use modules
Swiper.use([ Keyboard, Navigation, Controller, History, Autoplay,  Lazy, EffectFade, A11y, Thumbs ])

import {AbstractCarousel} from './AbstractCarousel';

/**
 * This is a variant of the testimonial slider where there are multiple photos
 * as well as multiple testimonial boxes.
 *
 * The constructor takes the id of the main slider wrapper, usually c-slider__inner
 */
export class TestimonialSliderStaticBg extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        let settings = {
            testimonialPhoto: {
                lazy: {
                    loadPrevNext: true,
                },
                autoplay: {
                    delay: 5000,
                },
                keyboard: {
                    enabled: true,
                },
                speed: 700,
                navigation: {
                    nextEl: '.c-slider__slidernav-next',
                    prevEl: '.c-slider__slidernav-prev',
                },
            }
        }
        Object.assign(settings, options)

        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.
        super(rootNode);

        this.$sliderContainer = this.$root.querySelector('[data-slider1-swiper]');

        this.testimonialPhoto = new Swiper(this.$sliderContainer, settings.testimonialPhoto);
    }
}


