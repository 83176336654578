import { Dropdown } from '../../../../lib/foundation.plugins'

/**
 * DropJS
 */

const _this =  {
    el: null,
    prevIndex: null,
    anchor: null,
    dropjs: Array.from(document.querySelectorAll('[data-dropjs-dropdown]')),
    dropdownInput: null,
    dropdownObj: null,
    dropdownSel: null,
    dropdownLblAnchor: null,
    dropdownUL: null,
    dropdownLI: null,
    activeLI: null,
    dropdownSearch: null,
    dropdownLIAttrbs: null,
    setOptionsTemp: [],
    setOptionsTextTemp: [],
    optionsDef: [],
    optionsStatus: false
}

function dropdownTriggered(el, anchor, index) {
    // store clicked dropdown to `_this.el`
    _this.el = el

    // store anchor dropdown to `_this.anchor`
    _this.anchor = anchor

    // if not the repeated dropdown is being clicked
    if( index !== prevIndex() && prevIndex() !== null ) {
        // set previous dropdown to it's original dropdown options
        renderOptionsDef()
        setSelectedOption()
        // set to FALSE to update to vars for the newly clicked dropdown
        _this.optionsStatus = false
        // clear previous dropdown search input
        _this.dropdownSearch.value = ''
    }
    // pass in index value
    _this.prevIndex = index
    updateElements(anchor)
    generateOptions()
    focusSearch()
    // Add new event for the dropdown input search
    if( _this.el.closest('[data-dropjs]').getAttribute('data-dropjs-search') === 'true' ) {
        _this.dropdownSearch.addEventListener('input', filterOption)
    }
    // Add new event for the dropdown `UP`, `DOWN`, `RETURN` keys
    _this.dropdownWrapUL.addEventListener('keydown', optionHighlightKey)
}

function prevIndex() {
    return _this.prevIndex
}

// Set new values for the clicked dropdown
function updateElements(anchor) {
    _this.dropdownWrapUL = _this.el.querySelector('[data-dropjs-wraplist]')
    _this.dropdownUL = _this.el.querySelector('[data-dropjs-list]')
    _this.dropdownLI = Array.from(_this.dropdownUL.querySelectorAll('[data-dropjs-item]'))
    _this.activeLI = _this.el.querySelector('.is-selected') || _this.el.querySelector('[data-dropjs-list] li')
    _this.dropdownSearch = _this.el.querySelector('[data-dropjs-search]')
    _this.dropdownLblAnchor = anchor
    _this.dropdownSel = _this.el.querySelector('[data-dropjs-select]')
    optionItemClickEvent()
}

// Set current option based on current select option value
function setSelectedOption() {
    clearSelectedOption()
    let currentValSel = _this.dropdownSel.options[0].innerText
    _this.optionsDef.forEach(function(item){
        if( item.innerText ===  currentValSel) {
            item.classList.add('is-selected')
        }
    })
}

// Option event for dropdown option click
function optionItemClickEvent() {
    _this.dropdownLI.forEach(function(item) {
        item.addEventListener('click', function(event){
            updateDropdownVal(event.target)
            clearSelectedOption()
            event.target.classList.add('is-selected')
            dropdownClose()
        })
    })
}

function focusSearch() {
    try {
        _this.dropdownSearch.focus()
    } catch(error) {
        // Expected error `Cannot read property focus of null`
    }
}

function clearSelectedOption() {
    _this.dropdownLI.forEach(function(item) {
        item.classList.remove('is-selected')
    })
    _this.optionsDef.forEach(function(item) {
        item.classList.remove('is-selected')
    })
}

function updateDropdownVal(selectedItem) {
    if( selectedItem && !selectedItem.classList.contains('no-results') ) {
        _this.activeLI = selectedItem
    }
    if( !_this.activeLI.classList.contains('no-results') ) {
        const selVal = _this.activeLI.innerText
        const selDataVal = _this.activeLI.getAttribute('data-value')
        // For Label
        _this.dropdownLblAnchor.innerText = selVal
        // For Select
        // set option value
        _this.dropdownSel.options[0].setAttribute('value', selDataVal)
        const event = new Event('change', { bubbles: true })
        _this.dropdownSel.dispatchEvent(event)
        // set option text
        _this.dropdownSel.options[0].innerText = selVal
        // Set option selected
        _this.dropdownSel.options[0].setAttribute('selected', '')
    }
}

function generateOptions() {
    // If '_this.optionsStatus' false
    if(_this.optionsStatus === false) {
        _this.setOptionsTemp = []
        _this.dropdownLI.forEach(function(item, index) {
            // Execute in first loop to pass in all option attributes
            (index === 0) ? _this.dropdownLIAttrbs = item.attributes : ''
            // store all current dropdown items to temporay array var
            _this.setOptionsTemp[index] = item
            // store all current dropdown innerText items to temporay array var
            _this.setOptionsTextTemp[index] = item.innerText
        })
        // Pass in to another array var, this var will have the original dropdown values
        _this.optionsDef = _this.setOptionsTemp
        // set to TRUE
        _this.optionsStatus = true
    }
}

function updateScrollView() {
    let currentScrollView = _this.dropdownUL
    let parentViewHeight = currentScrollView.clientHeight
    let listItemHeight = _this.dropdownUL.querySelector('li').clientHeight
    let listActiveItem = _this.activeLI
    let distanceVert = Math.abs(currentScrollView.getBoundingClientRect().top - listActiveItem.getBoundingClientRect().top)
    // Scroll up
    if( distanceVert > (parentViewHeight - listItemHeight)) {
        currentScrollView.scrollTop += listItemHeight
    }
    // Scroll down
    else if( distanceVert < listItemHeight / 2) {
        currentScrollView.scrollTop -= listItemHeight
    }
    // This will prevent the document viewport from scrolling
    event.preventDefault()
}

function optionHighlightKey(event) {
    // `Down` key
    if (event.which === 40) {
        _this.activeLI.classList.remove('is-selected')
        _this.activeLI = _this.activeLI.nextElementSibling || _this.activeLI
        if( !_this.activeLI.classList.contains('no-results') ) {
            _this.activeLI.classList.add('is-selected')
        }
        updateScrollView()
    }
    // `Up` key
    else if (event.which === 38) {
        _this.activeLI.classList.remove('is-selected')
        _this.activeLI = _this.activeLI.previousElementSibling || _this.activeLI
        if( !_this.activeLI.classList.contains('no-results') ) {
            _this.activeLI.classList.add('is-selected')
        }
        updateScrollView()
    }
    // `Enter/Return` key
    else if ( event.which === 13 ) {
        _this.dropdownLI.forEach(function(item) {
            if(item.classList.contains('is-selected')) {
                updateDropdownVal(item)
                dropdownClose()
            }
        })
    }
}

function renderOptions(listsOpt) {
    // set list empty to avoid undefined error
    let list = ''
    // loop through each list
    listsOpt.forEach(function(li) {
        // append each string tag item to `list` variable
        list += li.outerHTML
    })
    // Execute when nothing found to generate `No Results Found` item
    if(listsOpt.length === 0) {
        // create <li> element
        let li = document.createElement('li')
        // Loop through `_this.dropdownLIAttrbs` to generate it's attribute based on it's original DOM attributes
        for( var i = 0; _this.dropdownLIAttrbs.length > i; i++) {
            // Set each attribute
            li.setAttribute(_this.dropdownLIAttrbs[i].name, _this.dropdownLIAttrbs[i].value)
        }
        // add class `no-results`
        li.classList.add('no-results')
        // remove the `data-value` attr
        li.removeAttribute('data-value')
        // add text
        li.innerText = 'No results found'
        // append li string to `list` variable
        list += li.outerHTML
    }
    // Append to DOM
    _this.dropdownUL.innerHTML = list
    // update selected option
    setSelectedOption()
}

// This will render Default dropdown values of the previous dropdown
function renderOptionsDef() {
    // set list empty to avoid undefined error
    let list = ''
    // loop through each list
    _this.optionsDef.forEach(function(item) {
        // append each string tag item to `list` variable
        list += item.outerHTML
    })
    // Append to DOM
    _this.dropdownUL.innerHTML = list
}

// Close the the current open dropdown
function dropdownClose() {
    searchBlur()
    $(_this.el).foundation('close')
}

function searchBlur() {
    _this.dropdownSearch.blur()
}

function filterOption() {
    let filteredOptions = []
    // set the input keyword to lowercase
    let keyword = _this.dropdownSearch.value.toLowerCase()
    // Filter each value inside `_this.setOptionsTextTemp` based on the keyword input
    let filteredTextOptions = _this.setOptionsTextTemp.filter(function(option) {
        // set filted value to lowercase
        option = option.toLowerCase()
        // return the value
        return option.indexOf(keyword) > -1
    })

    // Loop through the filtered text array variable
    for( var i = 0; filteredTextOptions.length > i; i++ ) {
        // Loop through the dropdown items tags
        for(var j = 0; _this.setOptionsTemp.length > j; j++) {
            // set var for innerText
            let innerTextOpt = _this.setOptionsTemp[j].innerText
            // Compare if match
            if(filteredTextOptions[i].toLowerCase() === innerTextOpt.toLowerCase()) {
                // Push the found item
                filteredOptions.push(_this.setOptionsTemp[j])
            }
        }
    }
    // Render the items found in html tag
    renderOptions(filteredOptions)
    // set new values for the dropdown
    updateElements(_this.anchor)
}

function init() {
    _this.dropjs.forEach(function(el, index) {
        // initialise dropjs
        let dropdownJS = new Dropdown( $(el), { position: 'bottom', closeOnClick : true, autoFocus : true } )

        let dropdownAnchor = dropdownJS.$currentAnchor[0]

        $(el).on('show.zf.dropdown', function() {
            dropdownTriggered(el, dropdownAnchor, index)
        })
    })
}

export default init
