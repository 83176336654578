import {A11y, Autoplay, Controller, EffectFade, History, Keyboard, Lazy, Navigation, Swiper, Thumbs} from 'swiper/dist/js/swiper.esm'
import {AbstractCarousel} from './AbstractCarousel';
// Use modules
Swiper.use([Keyboard, Navigation, Controller, History, Autoplay, Lazy, EffectFade, A11y, Thumbs])

/**
 * This is a variant of the testimonial slider where there are multiple photos
 * as well as multiple testimonial boxes.
 *
 * The constructor takes the id of the main slider wrapper, usually c-related-post__inner
 */
export class ProductPageConfigurations extends AbstractCarousel {
    /**
     *
     * @param HTMLElement rootElement
     */
    constructor(rootNode, options = {}) {
        super(rootNode)

        this.settings = {
            menuSlider: {
                slidesPerView: 8,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.prod-config__slidernav-next',
                    prevEl: '.prod-config__slidernav-prev',
                },
                preventClicks: false,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                // Responsive breakpoints
                breakpoints: {
                    // when window width is <= 320px
                    375: {
                        slidesPerView: 1,
                    },
                    // when window width is <= 568px
                    568: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    // when window width is <= 640px
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    // when window width is <= 768px
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 15,
                    },
                    // when window width is <= 960px
                    960: {
                        slidesPerView: 5,
                        spaceBetween: 15,
                    },
                    // when window width is <= 1023px
                    1023: {
                        slidesPerView: 6,
                    },
                    // when window width is <= 1280px
                    1280: {
                        slidesPerView: 7,
                    }
                }
            },
            contentSlider: {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                allowTouchMove: false,
                autoHeight: true,
                keyboard: {
                    enabled: true,
                }
            }
        }

        Object.assign(this.settings, options)
        // Since we're extending an "AbstractCarousel", we have to call super()
        // before "this" is available.

        this.$contentContainer = this.$root.querySelector('[data-prodconfig-content]')
        this.$controllerContainer = this.$root.querySelector('[data-prodconfig-menu]')

        this.menuSlider = new Swiper(this.$controllerContainer, this.settings.menuSlider);
        this.settings.contentSlider.thumbs = {swiper: this.menuSlider};
        this.contentSlider = new Swiper(this.$contentContainer, this.settings.contentSlider);
    }
}


