// Logo animation
// ---

import { TimelineLite, CSSPlugin }  from 'gsap/TweenMax'
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [ CSSPlugin ]

let logoSVG = document.querySelector('.fox-blocks-logo')
let tl = new TimelineLite()

function logoAnimateMouseOver() {
    tl.addLabel('start')
    tl.to(logoSVG, .4, {autoAlpha:1})

    tl.addLabel('hide all logo elements')
    tl.to('.logo-el', .35, {autoAlpha:0})

    tl.addLabel('set default elements value')
    tl.set('.fbl__large-blocks-group', {x: -51})
    tl.set('.fbl__foxface', {scale: .8, x: 5})
    tl.set('.fbl__line', {autoAlpha:1})
    tl.set('.fbl__green-line', {scaleX: 0})
    tl.set('.fbl__red-line', {scaleY: 0, y: -3})
    tl.set('.fbl__top-text', {y: -3})
    tl.set('.fbl__bottom-text', {y: 6})

    tl.addLabel('show foxblocks large text')
    tl.to('.fbl__large-text', .25, {autoAlpha:1})

    tl.addLabel('move blocks group text to right')
    tl.to('.fbl__large-blocks-group', .5, {ease: Power2.easeInOut, x: 0})

    tl.addLabel('show reg')
    tl.to('.fbl__r', .5, {autoAlpha:1})

    tl.addLabel('show foxface')
    tl.to('.fbl__foxface', .5, {ease: Power2.easeInOut, autoAlpha:1, scale: 1, x: 0, delay: -1})

    tl.addLabel('expand green line')
    tl.to('.fbl__green-line', .5, {ease: Power1.easeInOut, scaleX: 1})

    tl.addLabel('expand red line')
    tl.to('.fbl__red-line', .5, {ease: Power1.easeInOut, scaleY: 1, y:0})

    tl.addLabel('show top text group')
    tl.staggerTo('.fbl__top-text', .2, {ease: Power2.easeOut, autoAlpha:1, y: 0}, .1)

    tl.addLabel('show bottom text group')
    tl.staggerTo('.fbl__bottom-text', .5, {ease: Power2.easeOut, autoAlpha:1, y: 0})
}

function check() {
    // Only execute animation if it's not active
    if( !tl.isActive() ) {
        logoAnimateMouseOver()
    }
}

function init() {
}

export default init
