/**
 * Controller Module
 */
import $ from 'jquery'
import {handleDownloadClick} from './authentication'

function bindModuleEvents() {
    // Handles events for clicking downloads
    $('body').on('click', '[data-download]', function (e) {
        e.preventDefault()
        const assetId = $(this).data('download') || null
        if (assetId) {
            handleDownloadClick(assetId, $(this))
        }
    })
}

function init() {
    bindModuleEvents()
}

export default init
