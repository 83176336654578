// ----------------
// UI Modules - Forms
// ----------------
// This is UI modules for Global instances
// ---------------------------------------
import dropJS from './dropdown/index'
import autosubmit from './autosubmit'

function init() {
    dropJS()
    autosubmit()
}

export default init
